import { defineMessages } from 'react-intl';

export default defineMessages({
    previousInsuranceInfoMsg: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.previousInsuranceInfoMsg",
        defaultMessage: '&lt;span&gt;Add any automobile policies held within the last &lt;b&gt;{years} years&lt;/b&gt;, or enter &quot;No Previous Insurance&quot; in the Insurer field.&lt;/span&gt;'
    },
    previousInsuranceHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.driverPreviousInsuranceHeading",
        defaultMessage: "Previous Insurance"
    },
    addPolicyBtn: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policyAddButton",
        defaultMessage: "Add policy"
    },
    insurerName: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.insurerName",
        defaultMessage: "Insurer"
    },
    policy: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy",
        defaultMessage: "Policy"
    },
    riskType: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.riskType",
        defaultMessage: "Risk Type"
    },
    claimHistoryReportHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Claims History Report / Letter of Experience",
        defaultMessage: "Claims History Report / Letter of Experience"
    },
    claimHistoryReportDate: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Claims history report / Letter of experience date",
        defaultMessage: "Claims history report / Letter of experience date"
    },
    autoPlusHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.AutoPlus",
        defaultMessage: "AutoPlus"
    },
    autoPlusDate: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.AutoPlus Date",
        defaultMessage: "Date AutoPlus ordered"
    },
    addManualDashEntryBtn: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Add Manual Entry",
        defaultMessage: "Add Manual Entry"
    },
    retrieveDashBtn: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Retrieve DASH",
        defaultMessage: "Retrieve DASH"
    },
    requiredToBindDash: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Required to bind the policy",
        defaultMessage: "Required to bind the policy"
    },
    dateRequested: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Date Ordered",
        defaultMessage: "Date Ordered"
    },
    dateReceived: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Date Received",
        defaultMessage: "Date Received"
    },
    orderStatus: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Status",
        defaultMessage: "Status"
    },
    source: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Source",
        defaultMessage: "Source"
    },
    requiredToBind: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.This is required to bind the policy",
        defaultMessage: "This is required to bind the policy"
    },
    emptyTitle: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Empty Title",
        defaultMessage: ""
    },
    reorderReport: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.Reorder Report",
        defaultMessage: "Loss history report was received already for Driver #{driverNum} {driverName}. Are you sure you would like to re-order?"
    }
});
