import { defineMessages } from 'react-intl';

export default defineMessages({
    locationName: {
        id: 'wmic.quoteandbind.ho.views.ho-your-home.Location Name',
        defaultMessage: 'Location Name'
    },
    residenceType: {
        id: 'wmic.quoteandbind.ho.views.ho-your-home.Residence Type',
        defaultMessage: 'Residence Type'
    },
    duplicateAddress: {
        id: 'quoteandbind.ho.views.ho-your-home.error.Duplicate Address',
        defaultMessage: '&lt;span&gt;Multiple Risks with the same address are not supported in BluePass. Add a &lt;b&gt;new location&lt;/b&gt; or refer to an underwriter.&lt;/span&gt;'
    },
    numberOfSuites: {
        id: 'quoteandbind.ho.views.ho-your-home.Number of Suites',
        defaultMessage: 'Number of Suites'
    },
    primaryLocation: {
        id: 'quoteandbind.ho.views.ho-your-home.PrimaryLocation',
        defaultMessage: 'Set as primary location'
    },
    outsideOfRateableTerritory: {
        id: 'quoteandbind.ho.views.ho-your-home.Risk is outside of rateable territory',
        defaultMessage: 'Risk is outside of rateable territory'
    },
    numberOfSelfContainedUnits: {
        id: 'quoteandbind.ho.views.ho-your-home.Number of Self Contained Units',
        defaultMessage: 'Number of Self Contained Units'
    },
    riskLocationError: {
        id: 'agent.views.new-quote.error.create.risk',
        defaultMessage: 'Could not create a risk out of AB, BC, MB, SK, ON and QC province'
    },
    extension: {
        id: 'quoteandbind.ho.views.ho-your-home.AssociatedDwelling.Extension',
        defaultMessage: 'Which dwelling is this risk associated with?'
    },
    laneway: {
        id: 'quoteandbind.ho.views.ho-your-home.AssociatedDwelling.Laneway',
        defaultMessage: 'Which dwelling shares the land with the laneway?'
    },
    isDwellingAvailable : {
        id : 'quoteandbind.ho.views.ho-your-home.ResidenceTypeLanewayMessage',
        defaultMessage : "A primary dwelling must be created first"
    },
    roomerBoarders: {
        id: 'quoteandbind.ho.views.ho-your-home.RoomersBoarders',
        defaultMessage: 'Any Roomers/Boarders'
    },
    roomerBoardersNumber: {
        id: 'quoteandbind.ho.views.ho-your-home.RoomersBoardersNumber',
        defaultMessage: 'Number of Roomers/Boarders'
    },
    taxExemption: {
        id: 'quoteandbind.ho.views.ho-your-home.Tax Exemption',
        defaultMessage: 'Tax Exemption'
    },
    taxExemptionReason: {
        id: 'quoteandbind.ho.views.ho-your-home.Tax Exemption Reason',
        defaultMessage: 'Tax Exemption Reason'
    },
    mobileMiniHomeQuestionnaireReceived: {
        id: 'quoteandbind.ho.views.ho-your-home.Mobile Mini Home Questionnaire Received',
        defaultMessage: 'Mobile/Mini Home Questionnaire Received?'
    },
    mobileMiniHomeQuestionnaireDate: {
        id: 'quoteandbind.ho.views.ho-your-home.Date Mobile Mini Home Questionnaire Received',
        defaultMessage: 'Date Mobile/Mini Home Questionnaire Received'
    },
    revenuePropertyQuestionnaireReceived: {
        id: 'quoteandbind.ho.views.ho-your-home.Revenue Property Questionnaire Received',
        defaultMessage: 'Revenue Property Questionnaire Received?'
    },
    revenuePropertyQuestionnaireDate: {
        id: 'quoteandbind.ho.views.ho-your-home.Revenue Property Questionnaire Date',
        defaultMessage: 'Date Revenue Property Questionnaire Received'
    },
});
