// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICListDownloadButton__downloadButton{border-style:none}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICListDownloadButton/WMICListDownloadButton.module.scss"],"names":[],"mappings":"AAAA,6CACI,iBAAA","sourcesContent":[".downloadButton {\n    border-style: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadButton": "app__WMICListDownloadButton__downloadButton"
};
export default ___CSS_LOADER_EXPORT___;
