const shouldScheduledItemsBeValidated = scheduledItems =>
    Array.isArray(scheduledItems) && scheduledItems.length > 0;

export const checkIfScheduledItemsHasNoDuplicate = (scheduledItems) => {
    if (!shouldScheduledItemsBeValidated(scheduledItems)) {
        return true;
    }
 
    const scheduledItemsCodeAndDescArr = scheduledItems.map(
        item => `${item.scheduledItemTypeCode}:${item.description.toLowerCase()}`
    );

    const scheduledItemsCodeAndDescSet = new Set(scheduledItemsCodeAndDescArr);

    return scheduledItemsCodeAndDescSet.size == scheduledItemsCodeAndDescArr.length;
};

