import React from 'react';

import { SUITES } from 'wmic-pe-portals-utils-js';
import { GatewayDocumentService } from 'wmic-pe-capability-gateway-document';
import { WMICButton } from 'wmic-pe-components-platform-react';

import styles from './WMICListDownloadButton.module.scss';

const handleDownloadButtonIsVisible = (entry) => {
    return entry.value.document?.isViewContentAvailable;
};

const handleDownloadButtonAction = (entry) => {
    const { publicID, sessionID } = entry.value.document;
    return GatewayDocumentService.downloadDocument(publicID, sessionID, SUITES.PC);
};

const handleDownloadButtonRender = (entry) => (
    <WMICButton
        className={styles.downloadButton}
        icon="gw-file-download"
        href={handleDownloadButtonAction(entry)}
        target="_blank"/>
);

const WMICListDownloadButton = {
    isVisible: handleDownloadButtonIsVisible,
    render: handleDownloadButtonRender
};

export default WMICListDownloadButton;
