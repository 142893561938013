/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useDataRefresh, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { getDataPropsResolver } from 'wmic-pe-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { DwellingInfoLookupService } from 'wmic-pe-capability-dwellinginfo';
import { JobType, WMICLogger, WMICRPCUtil, CONSTANTS, HOConstants, MODAL_CONSTANTS, POLICY_DETAILS, Position, WMICRichTextUtil, DeviceBreakpoint } from 'wmic-pe-portals-utils-js';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICValidationWarningUtil } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import WMICHOCoverageUtil from '../../utils/WMICHOCoverageUtil';
import styles from './WMICHODwellingPropertyDetails.module.scss';
import messages from './WMICHODwellingPropertyDetails.messages';
import metadata from './WMICHODwellingPropertyDetails.metadata.json5';

function isDefined(value) { return typeof value !== 'undefined'; }

const isValid = (item) => item && (item.aspects && item.aspects.valid && item.aspects.subtreeValid);

const formatAddress = (address) => `${address.addressLine1} ${address.city}, ${address.state}`;

const lanewayOptionsMapper = (dwelling) => ({
        code: dwelling.publicID,
        name: formatAddress(dwelling.yourHome.locationAddress)
    });

const handleEmptyResult = (submission, message) => {
    const data = `jobId=${submission.quoteID}, transactionType=${_.get(submission, 'baseData.jobType.code')}, error=${message}`;

    WMICLogger.error(data);
    WMICErrorHandler.processAsModal(message, submission.quoteID, submission.baseData.jobType);
};

const handleError = (submission, error) => {
    const data = `jobId=${submission.quoteID}, transactionType=${_.get(submission, 'baseData.jobType.code')} error=${error}`;

    WMICLogger.error(data);
    WMICErrorHandler.processAsModal(error, submission.quoteID, submission.baseData.jobType);
};

function WMICHODwellingPropertyDetails(props) {
    const {
        id,
        riskView,
        jobVM,
        isEditMode,
        onRiskViewChange,
        readOnly,
        showErrors,
        onValidate,
        selectedIndex
    } = props;

    const translator = useContext(TranslatorContext);
    const { refreshData } = useDataRefresh();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { showError } = useWizardModals();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const { authHeader } = useAuthentication();

    const [accountAddresses] = useState(_.get(jobVM, 'baseData.accountAddresses_WMIC.value'));
    const [backupAddress, setBackupAddress] = useState({});
    // Default the selected address index to what's in the data, or if nothing is set then use the first address
    const selectedAddressPublicID = _.get(riskView, 'yourHome.locationAddress.publicID.value');
    const selectedAddressIndex = selectedAddressPublicID ? accountAddresses.findIndex((a) => a.publicID === selectedAddressPublicID) : 0;
    const [riskLocationIndex, setRiskLocationIndex] = useState(selectedAddressIndex);
    const [isRiskLocationDisabled, setRiskLocationDisabled] = useState(false);
    const [associatedDwellingPublicId, setAssociatedDwellingPublicId] = useState();
    const [numberOfSuitesAndUnitLabel, setNumberOfSuitesAndUnitLabel] = useState('');
    const [showEditAddressDiv, setShowEditAddressDiv] = useState(false);
    const [csaLabelCode, setCsaLabelCode] = useState({
        aspects: riskView.yourHome.csaLabel_WMIC.aspects,
        value: undefined
    });
    const rentalProperty = HOConstants.dwellingUsageTypeRental;
    const revenuePropertyDwelling = HOConstants.revenuePropertyPolicyType;
    const seasonal = HOConstants.seasonalPolicyType
    const homeowner = HOConstants.homeownerPolicyType;

    const MAX_NUMBER_OF_SUITES_FOR_RPC_1411 = 3;
    const MIN_COV_A_LIMIT = 50000;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const CSA_LABEL_POLICY_TYPES = [
        HOConstants.homeownerPolicyType,
        HOConstants.rentersPolicyType,
        HOConstants.seasonalPolicyType,
        HOConstants.revenuePropertyPolicyType,
        HOConstants.mobileHomeownerPolicyType
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const CSA_LABEL_RESIDENCE_TYPES = [
        HOConstants.residenceTypeManufactured,
        HOConstants.residenceTypeMobile,
        HOConstants.residenceTypeMiniHome,
        HOConstants.residenceTypeMobileMiniHome
    ];

    const isNumberOfSuitesVisible = !!(jobVM
        && jobVM.baseData
        && jobVM.baseData.baseState
        && jobVM.baseData.baseState.value
        && jobVM.baseData.rateAsOfDate
        && WMICRPCUtil.getIsRPCEffective(jobVM.baseData.baseState.value.code, jobVM.baseData.rateAsOfDate.value, '1411'));

    const updateNumberOfSuitesAndUnitLabel = () => {
        const label = isNumberOfSuitesVisible ?
            translator(messages.numberOfSuites)
            : translator(messages.numberOfSelfContainedUnits);
        return label;
    }

    useEffect(() => {
        const label = updateNumberOfSuitesAndUnitLabel();
        setNumberOfSuitesAndUnitLabel(label);
    // First render only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            // We consider we're in a valid state if the form is valid AND we're not in the process of updating the risk location (showEditAddressDiv)
            onValidate(isComponentValid && !showEditAddressDiv, id);
        }
    }, [id, showEditAddressDiv, onValidate, isComponentValid])

    const typelistMapper = useCallback((typecode) => ({
            code: typecode.code,
            name: translator({
                id: typecode.name,
                defaultMessage: typecode.name
            })
        }), [translator]);

    const [availableRiskTypes, setAvailableRiskTypes] = useState(riskView.rating.hoPolicyType.aspects.availableValues.map(typelistMapper));
    const [residenceTypeList, setResidenceTypeList] = useState(riskView.yourHome.residenceType.aspects.availableValues.map(typelistMapper));
    const [coverageTypeList, setCoverageTypeList] = useState(riskView.yourHome.coverageType.aspects.availableValues.map(typelistMapper));
    const [dwellingUsageList, setDwellingUsageList] = useState(riskView.yourHome.dwellingUsage.aspects.availableValues.map(typelistMapper));
    const [occupancyList, setOccupancyList] = useState(riskView.yourHome.occupancy.aspects.availableValues.map(typelistMapper));
    const [showLocationErrors, setShowLocationErrors] = useState(false);

    const lanewayFilter = useCallback((dwelling) => {
        const filteredDwellingIDs = _.get(riskView, 'yourHome.associatedDwellingOptionIDs.value');

        return filteredDwellingIDs && filteredDwellingIDs.includes(dwelling.publicID);
    }, [riskView]);

    const [lanewayOptions, setLanewayOptions] = useState();

    const updateRiskView = useCallback(
        (newData) => {
            refreshData()
            onRiskViewChange(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onRiskViewChange]
    );

    const setRatingJurisdiction = useCallback(() => {
        const code = _.get(riskView, 'yourHome.locationAddress.state.value.code');

        if (code) {
            riskView.rating.ratingJurisdiction.value = jobVM.baseData.baseState.aspects.availableValues[0].typelist.getCode(code);
        }
    }, [riskView, jobVM]);

    const isSavedRisk = useCallback(() => !!riskView.publicID.value, [riskView]);

    const isRiskTypeSet = useCallback(() => !!_.get(riskView, 'rating.hoPolicyType.value.code'), [riskView]);

    const isCoverageTypeVisible = () => _.get(riskView, 'yourHome.coverageType.aspects.ocular');

    const resetResidenceTypeIfNecessary = useCallback((residenceTypes) => {
        const availableResidenceTypes = residenceTypes.map((residenceType) => residenceType.code);

        if (!availableResidenceTypes.includes(_.get(riskView, 'yourHome.residenceType.value.code'))) {
            riskView.yourHome.residenceType.value = null;
        }
    }, [riskView]);

    const computeResidenceTypeList = useCallback(() => {
        if (isRiskTypeSet()) {
            DwellingInfoLookupService.lookupAllowedResidenceTypes([
                riskView.rating.hoPolicyType.value.code,
                riskView.yourHome.locationAddress.country.value.code,
                jobVM.baseData.baseState.value.code,
                jobVM.baseData.rateAsOfDate.value,
                riskView.rating.ratingJurisdiction.value.code
            ], authHeader).then((codeList) => {
                let residenceTypes = [];

                if (!isDefined(codeList) || codeList.length === 0) {
                    setResidenceTypeList([]);
                    handleEmptyResult(jobVM.value, 'No values available for selecting residence type');
                } else {
                    residenceTypes = _.filter(
                        riskView.yourHome.residenceType.aspects.availableValues,
                        (item) => codeList.includes(item.code)
                    )
                        .map(typelistMapper);
                    setResidenceTypeList(residenceTypes);
                }

                resetResidenceTypeIfNecessary(residenceTypes);
            }).catch((error) => {
                setResidenceTypeList([]);
                handleError(jobVM.value, error);
            });
        }
    }, [riskView, jobVM, isRiskTypeSet, authHeader, setResidenceTypeList, resetResidenceTypeIfNecessary, typelistMapper]);

    const isRPCEffectiveForRiskType = useCallback((rpcNumber) => !!(riskView
            && riskView.rating.ratingJurisdiction
            && riskView.rating.ratingJurisdiction.value
            && jobVM.baseData.rateAsOfDate
            && riskView.rating.hoPolicyType
            && riskView.rating.hoPolicyType.value
            && WMICRPCUtil.getIsRPCEffectiveForRiskType(riskView.rating.ratingJurisdiction.value.code, jobVM.baseData.rateAsOfDate.value,
                riskView.rating.hoPolicyType.value.code, rpcNumber)), [riskView, jobVM]);

    const isResidenceTypeSet = useCallback(() => !!_.get(riskView, 'yourHome.residenceType.value.code'), [riskView]);

    const isCoverageALimitLessThanMin = useCallback(() => {
        if (isResidenceTypeSet()
            && riskView.yourHome.residenceType.value.code === HOConstants.residenceTypeMobileMiniHome
            && isRPCEffectiveForRiskType('1411')) {
            const dwellingCoverages = WMICHOCoverageUtil.getDwellingCoveragesForRisk(jobVM.lobData.homeowners, riskView.publicID.value);
            const coverageA = WMICHOCoverageUtil.getCoverageForRisk(dwellingCoverages.coverages, HOConstants.dwellingCoveragePublicID);
            const coverageATotalLimit = WMICHOCoverageUtil.getCoverageTotalLimit(coverageA);

            return (coverageATotalLimit < MIN_COV_A_LIMIT && coverageATotalLimit > 0);
        }

        return false;
    }, [isResidenceTypeSet, riskView.yourHome.residenceType.value, riskView.publicID.value, isRPCEffectiveForRiskType, jobVM.lobData.homeowners]);

    const isOccupancySet = useCallback(() => !!_.get(riskView, 'yourHome.occupancy.value.code'), [riskView]);

    const shouldDefaultAndDisableCoverageType = useCallback(() => isRiskTypeSet()
            && [revenuePropertyDwelling, homeowner]
                .includes(riskView.rating.hoPolicyType.value.code)
            && isOccupancySet()
            && riskView.yourHome.occupancy.value.code === HOConstants.vacant, [
        homeowner,
        isOccupancySet,
        isRiskTypeSet,
        revenuePropertyDwelling,
        riskView.rating.hoPolicyType.value,
        riskView.yourHome.occupancy.value
    ]);

    const isCoverageTypeSet = useCallback(() => !!(_.get(riskView, 'yourHome.coverageType.value.code')), [riskView]);

    const isCoverageTypeDisabled = useCallback(() => {
        if (!isRiskTypeSet()) {
            return true;
        }

        if (isRPCEffectiveForRiskType('1411')) {
            const isPolicyChangeOrRenewal = jobVM.baseData.jobType.value.code === JobType.POLICY_CHANGE;
            const isSubmission = jobVM.baseData.jobType.value.code === JobType.SUBMISSION;
            const currentNumberOfSuites = riskView
            && riskView.yourHome
            && riskView.yourHome.numberOfSelfContainedUnits
            && riskView.yourHome.numberOfSelfContainedUnits.value
                ? riskView.yourHome.numberOfSelfContainedUnits.value : 0;
            const hasTooManySuites = currentNumberOfSuites > MAX_NUMBER_OF_SUITES_FOR_RPC_1411;
            const claimsExceedLimit = riskView
                && riskView.rating
                && riskView.rating.doesPriorLossesExceedLimit
                && riskView.rating.doesPriorLossesExceedLimit.value === true;
            const coverageALimitLessThanMinAndMobileMini = isCoverageALimitLessThanMin();
            const lockCoverageTypeForSubmission = (
                hasTooManySuites
                    || claimsExceedLimit
                    || coverageALimitLessThanMinAndMobileMini
                    || shouldDefaultAndDisableCoverageType())
                && isSubmission
                && isCoverageTypeSet()
                && riskView.yourHome.coverageType.value.code === HOConstants.coverageTypeBasic;
            const lockCoverageTypeForPolicyChange = (
                hasTooManySuites
                    || claimsExceedLimit
                    || coverageALimitLessThanMinAndMobileMini
                    || shouldDefaultAndDisableCoverageType())
                && isPolicyChangeOrRenewal
                && ((isCoverageTypeSet()
                        && riskView.yourHome.coverageType.value.code === HOConstants.coverageTypeBasic)
                    || (riskView.rating.coverageTypeInPreviousPeriod
                        && riskView.rating.coverageTypeInPreviousPeriod.value
                        && riskView.rating.coverageTypeInPreviousPeriod.value.code === HOConstants.coverageTypeBasic)
                );

            return lockCoverageTypeForSubmission || lockCoverageTypeForPolicyChange;
        }

        return false;
    }, [isCoverageALimitLessThanMin, isCoverageTypeSet, isRPCEffectiveForRiskType, isRiskTypeSet, riskView, shouldDefaultAndDisableCoverageType, jobVM]);

    const isDwellingUsageValueSet = useCallback(() => !!(riskView
            && riskView.yourHome
            && riskView.yourHome.dwellingUsage
            && riskView.yourHome.dwellingUsage.value), [riskView]);

    const isDwellingUsageSet = useCallback(() => !!(riskView
            && riskView.yourHome
            && riskView.dwellingUsage
            && riskView.dwellingUsage.value
            && riskView.dwellingUsage.value.code), [riskView]);

    const resetDwellingUsageDependentFields = useCallback(() => {
        _.set(riskView, 'additionalDetails.seasonalQuestionnaire.condoQuestionnaireReceived.value', undefined);
        _.set(riskView, 'additionalDetails.seasonalQuestionnaire.condoQuestionnaireDate', undefined);
        _.set(riskView, 'additionalDetails.rentalQuestionnaire.condoQuestionnaireReceived', undefined);
        _.set(riskView, 'additionalDetails.rentalQuestionnaire.condoQuestionnaireDate', undefined);
        _.set(riskView, 'yourHome.timeOccupied', undefined);
        _.set(riskView, 'yourHome.absenteeLandlord_WMIC', undefined);
        _.set(riskView, 'yourHome.propertyManagement_WMIC', undefined);
        _.set(riskView, 'yourHome.occupancy.value', undefined);
        updateRiskView(riskView);
    }, [riskView, updateRiskView]);

    const setDwellingUsageDefaultValue = useCallback((dwellingUsages) => {
        if (isRiskTypeSet()
            && (riskView.rating.hoPolicyType.value.code === HOConstants.revenuePropertyPolicyType)
            && isRPCEffectiveForRiskType('1411')
        ) {
            if (dwellingUsages.length > 0
                && (!isDwellingUsageValueSet()
                    || (isDwellingUsageSet()
                        && riskView.yourHome.dwellingUsage.value.code !== rentalProperty)
                )
            ) {
                resetDwellingUsageDependentFields();
            }
        }
    }, [isRiskTypeSet, riskView, isDwellingUsageValueSet, isDwellingUsageSet, rentalProperty, resetDwellingUsageDependentFields, isRPCEffectiveForRiskType]);

    const resetDwellingUsageIfNecessary = useCallback((dwellingUsages) => {
        const availableDwellingUsages = dwellingUsages.map((dwelUsage) => dwelUsage.code);

        if (!availableDwellingUsages.includes(_.get(riskView, 'yourHome.dwellingUsage.value.code'))) {
            _.set(riskView, 'yourHome.dwellingUsage.value', null);
        }
    }, [riskView]);

    const resetOccupancyIfNecessary = useCallback(() => {
        const availableOccupancyTypes = occupancyList.map((occType) => occType.code);

        if (!availableOccupancyTypes.includes(_.get(riskView, 'yourHome.occupancy.value.code'))) {
            riskView.yourHome.occupancy.value = null;
        }
    }, [occupancyList, riskView]);

    const computeOccupancyTypeList = useCallback(() => {
        if (isRiskTypeSet()) {
            DwellingInfoLookupService.lookupDwellingOccupancyValues([
                riskView.yourHome.locationAddress.country.value.code,
                riskView.rating.hoPolicyType.value.code,
                _.get(riskView, 'yourHome.coverageFor.value.code', HOConstants.coverageForOtherStructure),
                _.get(riskView, 'yourHome.dwellingUsage.value.code', HOConstants.dwellingUsageTypePrimary),
                jobVM.baseData.rateAsOfDate.value,
                jobVM.baseData.baseState.value.code
            ], authHeader).then((codeList) => {
                if (!isDefined(codeList) || codeList.length === 0) {
                    setOccupancyList([]);
                    handleEmptyResult(jobVM.value, 'No values available for selecting occupancy');
                } else {
                    const availableOccupancyList = _.filter(
                        riskView.yourHome.occupancy.aspects.availableValues,
                        (item) => codeList.includes(item.code)
                    )
                        .map(typelistMapper);

                    setOccupancyList(availableOccupancyList);
                }

                resetOccupancyIfNecessary();
            }).catch((error) => {
                setOccupancyList([]);
                handleError(jobVM.value, error);
            });
        }
    }, [isRiskTypeSet, riskView, jobVM.baseData.rateAsOfDate.value, jobVM.baseData.baseState.value, jobVM.value, authHeader, resetOccupancyIfNecessary, typelistMapper]);

    const coverageTypeApplicableToBeResetToBasic = useCallback(() => riskView.yourHome.residenceType != HOConstants.condominiumPolicyType && ((riskView.yourHome.coverageType && !riskView.yourHome.coverageType.value)
            || _.get(riskView, 'yourHome.coverageType.value.code') !== HOConstants.coverageTypeBasic, [riskView]));

    const riskHasTooManySuites = useCallback(() => {
        if (isRPCEffectiveForRiskType('1411')) {
            const previousNumberOfSuites = riskView
            && riskView.yourHome
            && riskView.yourHome.numberOfSelfContainedUnitsInPreviousPeriod
            && riskView.yourHome.numberOfSelfContainedUnitsInPreviousPeriod.value
                ? riskView.yourHome.numberOfSelfContainedUnitsInPreviousPeriod.value : 0;
            const currentNumberOfSuites = riskView
            && riskView.yourHome
            && riskView.yourHome.numberOfSelfContainedUnits
            && riskView.yourHome.numberOfSelfContainedUnits.value
                ? riskView.yourHome.numberOfSelfContainedUnits.value : 0;

            return previousNumberOfSuites !== currentNumberOfSuites && currentNumberOfSuites > MAX_NUMBER_OF_SUITES_FOR_RPC_1411;
        }

        return false;
    }, [isRPCEffectiveForRiskType, riskView]);

    const riskWithTooManySuitesHadBasicCoverageType = useCallback(() => {
        if (isRPCEffectiveForRiskType('1411')) {
            const currentNumberOfSuites = riskView
            && riskView.yourHome
            && riskView.yourHome.numberOfSelfContainedUnits
            && riskView.yourHome.numberOfSelfContainedUnits.value
                ? riskView.yourHome.numberOfSelfContainedUnits.value : 0;

            return currentNumberOfSuites > MAX_NUMBER_OF_SUITES_FOR_RPC_1411
                && riskView.rating.coverageTypeInPreviousPeriod
                && riskView.rating.coverageTypeInPreviousPeriod.value
                && riskView.rating.coverageTypeInPreviousPeriod.value.code === HOConstants.coverageTypeBasic;
        }

        return false;
    }, [isRPCEffectiveForRiskType, riskView]);

    const resetCoverageTypeForTooManySuitesConditionIfNeeded = useCallback(() => {
        if (riskView && coverageTypeApplicableToBeResetToBasic()
            && (riskHasTooManySuites() || riskWithTooManySuitesHadBasicCoverageType())) {
            if (_.get(riskView, 'yourHome.coverageType.value') !== undefined ) {
                WMICValidationWarningUtil.showCoverageTypeChangedToBasic(riskView, translator, viewModelService);
            }
            _.set(riskView, 'yourHome.coverageType.value', HOConstants.coverageTypeBasic);
        }
    }, [coverageTypeApplicableToBeResetToBasic, riskHasTooManySuites, riskView, riskWithTooManySuitesHadBasicCoverageType, translator, viewModelService]);

    const riskClaimsCountExceeded = useCallback(() => !!(isRPCEffectiveForRiskType('1411')
            && _.get(riskView, 'rating.doesPriorLossesExceedLimit.value') === true), [isRPCEffectiveForRiskType, riskView]);

    const resetCoverageTypeForClaimsCountExceedLimitConditionIfNeeded = useCallback(() => {
        if (riskView
            && coverageTypeApplicableToBeResetToBasic()
            && riskClaimsCountExceeded()) {
            _.set(riskView, 'yourHome.coverageType.value', HOConstants.coverageTypeBasic);
            WMICValidationWarningUtil.showCoverageTypeChangedToBasic(riskView, translator, viewModelService);
        }
    }, [coverageTypeApplicableToBeResetToBasic, riskClaimsCountExceeded, riskView, translator, viewModelService]);

    const resetCoverageTypeIfNecessary = useCallback((coverageList) => {
        resetCoverageTypeForTooManySuitesConditionIfNeeded();
        resetCoverageTypeForClaimsCountExceedLimitConditionIfNeeded();

        const availableCoverageTypes = coverageList.map((covType) => covType.code);

        if (isCoverageTypeSet()
            && !availableCoverageTypes.includes(riskView.yourHome.coverageType.value.code)) {
                if (availableCoverageTypes.includes(HOConstants.coverageTypeBasic)) {
                    _.set(riskView, 'yourHome.coverageType.value', HOConstants.coverageTypeBasic);
                }
                _.set(riskView, 'yourHome.coverageType.value', null);
        }
    }, [isCoverageTypeSet, resetCoverageTypeForClaimsCountExceedLimitConditionIfNeeded, resetCoverageTypeForTooManySuitesConditionIfNeeded, riskView]);

    const computeCoverageTypeList = useCallback(() => {
        if (isRiskTypeSet() && isCoverageTypeVisible()) {
            DwellingInfoLookupService.lookupCoverageTypeValues([
                riskView.yourHome.locationAddress.country.value.code,
                riskView.rating.hoPolicyType.value.code,
                _.get(riskView, 'yourHome.occupancy.value.code', HOConstants.occupancyTypeOther),
                _.get(riskView, 'yourHome.coverageFor.value.code', HOConstants.coverageForOtherStructure),
                _.get(riskView, 'yourHome.residenceType.value.code', HOConstants.residenceTypeOther),
                _.get(riskView, 'yourHome.dwellingUsage.value.code', HOConstants.dwellingUsageTypePrimary),
                jobVM.baseData.rateAsOfDate.value,
                jobVM.baseData.baseState.value.code,
            ], authHeader).then((codeList) => {
                let coverageList = [];

                if (!isDefined(codeList) || codeList.length === 0) {
                    setCoverageTypeList([]);
                    handleEmptyResult(jobVM.value, 'No values available for selecting coverage type');
                } else {
                    coverageList = _.filter(riskView.yourHome.coverageType.aspects.availableValues,
                        (item) => codeList.includes(item.code))
                        .map(typelistMapper);
                    setCoverageTypeList(coverageList);
                }

                resetCoverageTypeIfNecessary(coverageList);
            }).catch((error) => {
                setCoverageTypeList([]);
                handleError(jobVM.value, error);
            });
        }
    // Disable due to non-callback function dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRiskTypeSet, riskView, jobVM.baseData.rateAsOfDate.value, jobVM.baseData.baseState.value.code, jobVM.value, authHeader, resetCoverageTypeIfNecessary, typelistMapper]);

    const computeDwellingUsageTypeList = useCallback(() => {
        if (isRiskTypeSet()) {
            DwellingInfoLookupService.lookupDwellingUsageValues(
                riskView.yourHome.locationAddress.country.value.code,
                riskView.rating.hoPolicyType.value.code,
                jobVM.baseData.baseState.value.code,
                jobVM.baseData.rateAsOfDate.value,
                authHeader
            ).then((codeList) => {
                let dwellingUsages = [];

                if (!isDefined(codeList) || codeList.length === 0) {
                    setDwellingUsageList([]);
                    handleEmptyResult(jobVM.value, 'No values available for selecting dwelling usage');
                } else {
                    dwellingUsages = _.filter(
                        riskView.yourHome.dwellingUsage.aspects.availableValues,
                        (item) => codeList.includes(item.code)
                    )
                        .map(typelistMapper);
                    setDwellingUsageList(dwellingUsages);
                    setDwellingUsageDefaultValue(dwellingUsages);
                }

                resetDwellingUsageIfNecessary(dwellingUsages);
            })
                .then(() => {
                    computeCoverageTypeList();
                    computeOccupancyTypeList();
                })
                .catch((error) => {
                    setDwellingUsageList([]);
                    handleError(jobVM.value, error);
                });
        }
    }, [authHeader, computeCoverageTypeList, computeOccupancyTypeList, isRiskTypeSet, resetDwellingUsageIfNecessary, typelistMapper, riskView.rating.hoPolicyType.value, riskView.yourHome.dwellingUsage.aspects.availableValues, riskView.yourHome.locationAddress, setDwellingUsageDefaultValue, jobVM]);

    const setTypeDependentLists = useCallback(() => {
        computeResidenceTypeList();
        computeDwellingUsageTypeList();
    }, [computeResidenceTypeList, computeDwellingUsageTypeList]);

    const hasCsaLabel = useCallback(() => isRiskTypeSet()
            && isResidenceTypeSet()
            && CSA_LABEL_POLICY_TYPES.includes(riskView.rating.hoPolicyType.value.code)
            && CSA_LABEL_RESIDENCE_TYPES.includes(riskView.yourHome.residenceType.value.code), [
        CSA_LABEL_POLICY_TYPES,
        CSA_LABEL_RESIDENCE_TYPES,
        isResidenceTypeSet,
        isRiskTypeSet,
        riskView.rating.hoPolicyType.value,
        riskView.yourHome.residenceType.value
    ]);

    const isCsaLabelCode = useCallback((code) => _.get(riskView, 'yourHome.csaLabel_WMIC.value.code') === code, [riskView]);

    const clearCsaLabelDependentFields = useCallback(() => {
        if (!isCsaLabelCode(HOConstants.otherCode)) {
            _.set(riskView, 'yourHome.csaDescription_WMIC.value', null);
        }

        if (!(isRPCEffectiveForRiskType('1411') && isCsaLabelCode('yes'))) {
            _.set(riskView, 'yourHome.csaLabelType_WMIC.value', null);
        }
    }, [isCsaLabelCode, isRPCEffectiveForRiskType, riskView]);

    const clearCsaLabelTypeOtherField = useCallback((value, path) => {
        if (!value || value !== HOConstants.otherCode) {
            _.set(riskView, 'yourHome.csaLabelTypeOther_WMIC.value', null);
        }

        _.set(riskView, path, value);
        updateRiskView(riskView);
    }, [updateRiskView, riskView]);

    const clearCsaFieldsIfNeeded = useCallback(() => {
        if (!hasCsaLabel()) {
            _.set(riskView, 'yourHome.csaLabel_WMIC.value', null);
            setCsaLabelCode({ ...csaLabelCode, value: null });
        }

        clearCsaLabelDependentFields();
    }, [clearCsaLabelDependentFields, csaLabelCode, hasCsaLabel, riskView]);

    const changedRiskType = useCallback((value) => {
        if (isDefined(value)) {
            riskView.rating.hoPolicyType.value = { code: value };
            setTypeDependentLists();
            clearCsaFieldsIfNeeded();
        }

        const label = updateNumberOfSuitesAndUnitLabel();

        setNumberOfSuitesAndUnitLabel(label);
    }, [setNumberOfSuitesAndUnitLabel, clearCsaFieldsIfNeeded, riskView, setTypeDependentLists]);

    const isResidenceTypeLaneway = useCallback(() => _.get(riskView, 'yourHome.residenceType.value.code') === HOConstants.residenceTypeLaneway, [riskView]);

    const isPolicyTypePersonalPropertyExtension = () => _.get(riskView, 'rating.hoPolicyType.value.code') === HOConstants.extensionPolicyType;

    const filterLanewayOptions = useCallback(() => {
        const options = _.filter(jobVM.lobData.homeowners.coverables.dwellings.value, (dwelling) => lanewayFilter(dwelling));

        if (options.length > 0) {
            setLanewayOptions(options.map(lanewayOptionsMapper));
        }
    }, [lanewayFilter, jobVM.lobData.homeowners.coverables.dwellings.value]);

    const residenceTypeChanged = useCallback((value, path) => {
        _.set(riskView, path, value);

        computeCoverageTypeList();

        if (!isResidenceTypeLaneway() && riskView.yourHome.associatedDwellingPublicID && riskView.yourHome.associatedDwellingPublicID.value) {
            const associatedPrimary = _.find(jobVM.lobData.homeowners.coverables.dwellings.value, (dwelling) => dwelling.publicID === riskView.yourHome.associatedDwellingPublicID.value);

            associatedPrimary.associatedDwellingPublicID = null;
            _.set(riskView, 'yourHome.associatedDwellingPublicID.value', null);
        }

        updateRiskView(riskView);

        filterLanewayOptions();
        clearCsaFieldsIfNeeded();
    }, [clearCsaFieldsIfNeeded, computeCoverageTypeList, filterLanewayOptions, isResidenceTypeLaneway, updateRiskView, riskView, jobVM.lobData.homeowners.coverables.dwellings.value]);

    const onNumberOfSelfContainedUnitsChange = useCallback((value, path) => {
        _.set(riskView, path, value);
        updateRiskView(riskView);
        resetCoverageTypeForTooManySuitesConditionIfNeeded();
    }, [updateRiskView, resetCoverageTypeForTooManySuitesConditionIfNeeded, riskView]);

    const disabledDwellingUsage = useCallback(() => {
        return !isRiskTypeSet() || (isRiskTypeSet() && (riskView.rating.hoPolicyType.value.code === HOConstants.revenuePropertyPolicyType) && isRPCEffectiveForRiskType('1411'));
    }, [isRPCEffectiveForRiskType, isRiskTypeSet, riskView.rating.hoPolicyType.value]);

    const dwellingUsageChanged = useCallback((value, path) => {
        _.set(riskView, path, value);
        updateRiskView(riskView);
        resetDwellingUsageDependentFields();
        computeCoverageTypeList();
        computeOccupancyTypeList();
    }, [riskView, updateRiskView, resetDwellingUsageDependentFields, computeCoverageTypeList, computeOccupancyTypeList]);

    const defaultCoverageTypeWhenApplicable = useCallback(() => {
        if (shouldDefaultAndDisableCoverageType() && coverageTypeApplicableToBeResetToBasic()
            && isRPCEffectiveForRiskType('1411')) {
            _.set(riskView, 'yourHome.coverageType.value', HOConstants.coverageTypeBasic);
            updateRiskView(riskView);
            WMICValidationWarningUtil.showCoverageTypeChangedToBasic(riskView, translator, viewModelService);
        }
    }, [coverageTypeApplicableToBeResetToBasic, isRPCEffectiveForRiskType, riskView, shouldDefaultAndDisableCoverageType, translator, updateRiskView, viewModelService]);

    const hasOwnContractor = useCallback((occupancy) => {
        const applicableRiskTypes = [HOConstants.revenuePropertyPolicyType, HOConstants.seasonalPolicyType, HOConstants.homeownerPolicyType];
        const currentOccupancy = occupancy || _.get(riskView, 'yourHome.occupancy.value.code');

        return !!(currentOccupancy === HOConstants.occupancyTypeUnderConstruction
            && applicableRiskTypes.includes(_.get(riskView, 'rating.hoPolicyType.value.code')));
    }, [riskView]);

    const occupancyChanged = useCallback((value, path) => {
        _.set(riskView, path, value);

        if (value !== HOConstants.occupancyTypeImmediateFamily) {
            _.set(riskView, 'yourHome.immediateFamilyType_WMIC.value', undefined);
            _.set(riskView, 'yourHome.immediateFamilyDesc_WMIC.value', undefined);
        }

        if (!hasOwnContractor(value)) {
            _.set(riskView, 'yourHome.ownContractor_WMIC.value', undefined);
        }

        if (value !== HOConstants.occupancyTypeVacant) {
            _.set(riskView, 'yourHome.vacantDays_WMIC.value', undefined);
        }

        if (value !== HOConstants.occupancyTypeOther) {
            _.set(riskView, 'yourHome.describeOtherOccupancy.value', undefined);
        }

        _.set(riskView, 'yourHome.absenteeLandlord_WMIC.value', undefined);
        _.set(riskView, 'yourHome.propertyManagement_WMIC.value', undefined);
        _.set(riskView, 'yourHome.questionnaireReceived.value', undefined);
        _.set(riskView, 'yourHome.questionnaireDate.value', undefined);
        updateRiskView(riskView);
        computeCoverageTypeList();
        defaultCoverageTypeWhenApplicable();
    }, [computeCoverageTypeList, defaultCoverageTypeWhenApplicable, hasOwnContractor, riskView, updateRiskView]);

    const resetQuestionnaire = useCallback((value, path) => {
        _.set(riskView, path, value);
        _.set(riskView, 'yourHome.questionnaireDate.value', undefined);
        updateRiskView(riskView);
    }, [riskView, updateRiskView]);

    const immediateFamilyChanged = useCallback((value, path) => {
        _.set(riskView, path, value);
        _.set(riskView, 'yourHome.immediateFamilyDesc_WMIC', undefined);
        updateRiskView(riskView);
    }, [updateRiskView, riskView]);

    const isAbsenteeLandlordVisible = useCallback(() => {
        const ratingJurisdiction = _.get(riskView, 'rating.ratingJurisdiction.value.code');
        const occupancyType = _.get(riskView, 'yourHome.occupancy.value.code');
        const hoPolicyType = _.get(riskView, 'rating.hoPolicyType.value.code');
        const applicableRiskTypes = [HOConstants.revenuePropertyPolicyType, HOConstants.condominiumPolicyType];
        const dwellingUsageType = _.get(riskView, 'yourHome.dwellingUsage.value.code');

        if (dwellingUsageType === HOConstants.dwellingUsageTypeRental && [HOConstants.occupancyTypeVacant, HOConstants.occupancyTypeUnderRenovation].includes(occupancyType)) {
            return true;
        }

        if (occupancyType === HOConstants.occupancyTypeTenant) {
            return ratingJurisdiction === HOConstants.ho_SEO
                ? (isRPCEffectiveForRiskType('1411') && hoPolicyType === HOConstants.revenuePropertyPolicyType) || hoPolicyType === HOConstants.condominiumPolicyType
                : applicableRiskTypes.includes(hoPolicyType);
        }

        return false;
    }, [isRPCEffectiveForRiskType, riskView]);

    const isPropertyManagementVisible = useCallback(() => {
        const ratingJurisdiction = _.get(riskView, 'rating.ratingJurisdiction.value.code');
        const occupancyType = _.get(riskView, 'yourHome.occupancy.value.code');
        const hoPolicyType = _.get(riskView, 'rating.hoPolicyType.value.code');
        const absenteeLandlord = _.get(riskView, 'yourHome.absenteeLandlord_WMIC.value');
        const applicableOccupancyTypes = [HOConstants.occupancyTypeVacant, HOConstants.occupancyTypeTenant];

        return ((isRPCEffectiveForRiskType('1411') && occupancyType === HOConstants.occupancyTypeTenant && absenteeLandlord)
            || (applicableOccupancyTypes.includes(occupancyType) && absenteeLandlord)
            || (WMICRPCUtil.getIsRPCEffectiveForRiskType(ratingJurisdiction, jobVM.baseData.rateAsOfDate.value,
                hoPolicyType, '1378') && absenteeLandlord !== undefined));
    }, [isRPCEffectiveForRiskType, riskView, jobVM.baseData.rateAsOfDate.value]);

    const coverageForChanged = useCallback((value, path) => {
        _.set(riskView, path, value);
        updateRiskView(riskView);
        computeCoverageTypeList();
    }, [computeCoverageTypeList, riskView, updateRiskView]);

    const superiorShuttleTankerVisible = useCallback(() => {
        if (_.get(riskView, 'rating.ratingJurisdiction.value.code') === HOConstants.ho_QC) {
            return false;
        }

        return !(isRPCEffectiveForRiskType('1378') || isRPCEffectiveForRiskType('1379'));
    }, [isRPCEffectiveForRiskType, riskView]);

    useEffect(() => {
        filterLanewayOptions();

        if (!isEditMode) {
            return;
        }

        if (riskView.yourHome.locationAddress
            && riskView.yourHome.locationAddress.value
            && riskView.yourHome.locationAddress.value.addressLine1) {
            const index = _.findIndex(
                accountAddresses,
                (address) => address.displayName === riskView.yourHome.locationAddress.value.displayName
            );

            setRiskLocationIndex(index);
        } else {
            setRiskLocationIndex(0);
            riskView.yourHome.locationAddress = _.clone(accountAddresses[0]);
            setRatingJurisdiction();
        }

        if (_.get(riskView, 'yourHome.csaLabel_WMIC.value.code')) {
            setCsaLabelCode((prev) => ({
                    ...prev,
                    value: _.get(riskView, "yourHome.csaLabel_WMIC.value.code")
                }));
        }

        setTypeDependentLists();

        if (!isRiskTypeSet()) {
            DwellingInfoLookupService.lookupPolicyTypeFilterValues(
                jobVM.baseData.baseState.value.code,
                jobVM.baseData.rateAsOfDate.value,
                authHeader
            ).then((codeList) => {
                if (!isDefined(codeList) || codeList.length === 0) {
                    setAvailableRiskTypes([]);
                    handleEmptyResult(jobVM.value, 'No values available for selecting risk type');
                } else {
                    const riskTypes = _.filter(
                        riskView.rating.hoPolicyType.aspects.availableValues,
                        (item) => codeList.includes(item.code)
                    ).map(typelistMapper);

                    setAvailableRiskTypes(riskTypes);
                }
            }).catch((error) => {
                setAvailableRiskTypes([]);
                handleError(jobVM.value, error);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskView.value, isEditMode]);

    const getPreferredAddressDropDownValues = useCallback(() => {
        if (accountAddresses) {
            return accountAddresses.map((address, index) => {
                const addressArrayJoinKey = {
                    code: _.toString(index),
                    name: address.displayName
                };

                return addressArrayJoinKey;
            });
        }

        return [];
    }, [accountAddresses]);

    const handleAddressDropDownValueChange = useCallback((addressIndex) => {
        const updatedLocations = accountAddresses.find((address, index) => index === parseInt(addressIndex, 10));

        _.set(riskView, 'yourHome.locationAddress', updatedLocations);
        setRiskLocationIndex(addressIndex);
        updateRiskView(riskView);
        setRatingJurisdiction();
    }, [accountAddresses, riskView, setRatingJurisdiction, updateRiskView]);

    const createAddressVM = useCallback((model) => viewModelService.create(
            model,
            'pc',
            'wmic.edge.ca.capabilities.address.dto.AddressDTO'
        ), [viewModelService]);

    const addAddress = useCallback(() => {
        setRiskLocationDisabled(true);
        setShowEditAddressDiv(true);
        riskView.value.isAddingLocation = showEditAddressDiv;

        const newAddress = createAddressVM({ country: CONSTANTS.COUNTRY.CA });

        _.set(riskView, 'yourHome.locationAddress.value', newAddress.value);
    }, [createAddressVM, riskView, showEditAddressDiv]);

    const unitNumberChanged = useCallback((value, path) => {
        _.set(riskView, path, value);
        updateRiskView(riskView);
    }, [riskView, updateRiskView]);

    const updateLanewayLocation = (value) => {
        if (isResidenceTypeLaneway()) {
            const associatedDwelling = _.find(jobVM.lobData.homeowners.coverables.dwellings.value, (dwelling) =>
                dwelling.publicID === value);

            riskView.yourHome.locationAddress = _.clone(associatedDwelling.yourHome.locationAddress);
        }
    };

    const lanewayOptionsChange = useCallback((value, path) => {
        const addresses = _.get(jobVM, 'baseData.accountAddresses_WMIC', []);

        if (addresses && addresses.value.length !== 0 && (isResidenceTypeLaneway() || isPolicyTypePersonalPropertyExtension())) {
            updateLanewayLocation(value);
            _.set(riskView, path, value);
            setAssociatedDwellingPublicId(value);
            updateRiskView(riskView);
            riskView.yourHome.locationAddress.publicID = undefined;

        }
    }, [jobVM, isPolicyTypePersonalPropertyExtension, riskView, updateRiskView]);

    const isLanewayOptionsDisabled = () => !(lanewayOptions && lanewayOptions.length > 0);

    const isOutsideOfRateableTerritoryMessageVisible = () => {
        const riskLocationJurisdiction = _.get(riskView, 'yourHome.locationAddress.state.value.code') ?? _.get(riskView, 'yourHome.locationAddress.province.value.code');
        return !showEditAddressDiv && !POLICY_DETAILS.BLUEPASS_HO_SUPPORTED_JURISDICTIONS.includes(riskLocationJurisdiction);
    };

    const restoreAddress = useCallback((backup) => {
        riskView.yourHome.locationAddress.addressLine1.value = backup.addressLine1;
        riskView.yourHome.locationAddress.addressLine2.value = backup.addressLine2;
        riskView.yourHome.locationAddress.city.value = backup.city;
        riskView.yourHome.locationAddress.state.value = backup.state;
        riskView.yourHome.locationAddress.postalCode.value = backup.postalCode;
        riskView.yourHome.locationAddress.country.value = backup.country;
    }, [riskView.yourHome.locationAddress]);

    const createBackupAddress = (address) => ({
            addressLine1: address.addressLine1.value,
            addressLine2: address.addressLine2.value,
            city: address.city.value,
            state: address.state.value,
            postalCode: address.postalCode.value,
            country: address.country.value.code,
            displayName: address.displayName.value
        });

    const isDuplicateAddress = (objectA, objectB) => {
        // eslint-disable-next-line prefer-object-spread
        const a = Object.assign({}, objectA);
        // eslint-disable-next-line prefer-object-spread
        const b = Object.assign({}, objectB);
        const propsToIgnore = ['publicID', '$$hashKey', 'country', 'addressType', 'displayName'];

        propsToIgnore.forEach((prop) => {
            delete a[prop];
            delete b[prop];
        });

        const aProps = Object.getOwnPropertyNames(a);

        for (let i = 0; i < aProps.length; i++) {
            const propName = aProps[i];

            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        return true;
    };

    const checkForDuplicatesAcrossRisks = useCallback(() => {
        riskView.hasDuplicateAddress = accountAddresses.some((dwellingAddress) => isDuplicateAddress(dwellingAddress, riskView.yourHome.locationAddress.value));
    }, [accountAddresses, riskView]);

    const cancelAddressChange = useCallback(() => {
        setRiskLocationDisabled(false);
        setShowEditAddressDiv(false);
        setShowLocationErrors(false);
        riskView.value.isAddingLocation = false;

        if (backupAddress) {
            restoreAddress(backupAddress);
        }
    }, [backupAddress, restoreAddress, riskView.value]);

    const updateAddress = useCallback(() => {
        if (isValid(riskView.yourHome.locationAddress)) {
            setShowEditAddressDiv(false);
            setShowLocationErrors(false);

            for(let addressIndex = 0; addressIndex < accountAddresses.length; addressIndex++){
                const {displayName, publicID, addressLine2, ...addressToMatch} = accountAddresses[addressIndex];

                if(JSON.stringify(addressToMatch) === JSON.stringify(riskView.yourHome.locationAddress.value)){
                    setRiskLocationIndex(addressIndex);
                    setRiskLocationDisabled(false);

                    return;
                }
            }

            riskView.value.isAddingLocation = false;

            const riskLocationState = riskView.yourHome.locationAddress.state.value ? riskView.yourHome.locationAddress.state.value : riskView.yourHome.locationAddress.province.value;

            if (['AB', 'SK', 'MB', 'BC', 'ON', 'NB', 'NS', 'PE', 'YT', 'QC'].includes(riskLocationState.code)) {
                const address = riskView.yourHome.locationAddress;
                const stateCode = riskLocationState?.code;

                address.displayName.value = `${accountAddresses.length + 1}: ${address.addressLine1.value}, ${address.city.value}, ${stateCode}`;
                setBackupAddress(createBackupAddress(address));
                setRiskLocationDisabled(false);
                checkForDuplicatesAcrossRisks();
                accountAddresses.push(_.clone(address.value));
                setRiskLocationIndex(accountAddresses.length - 1);
            } else {
                showError({
                    status: MODAL_CONSTANTS.STATUS.ERROR,
                    icon: MODAL_CONSTANTS.ICON.ERROR,
                    title: platformMessages.errorModalHeader,
                    message: messages.riskLocationError,
                });
                restoreAddress(backupAddress);
            }
        }
        else {
            setShowLocationErrors(true);
        }

        setRatingJurisdiction();
    }, [riskView.yourHome.locationAddress, riskView.value, setRatingJurisdiction, accountAddresses, checkForDuplicatesAcrossRisks, showError, restoreAddress, backupAddress]);

    const getAssociatedDwellingTranslationKey = () => isPolicyTypePersonalPropertyExtension()
            ? messages.extension
            : messages.laneway;

    const showAssociatedDwelling =()=>isResidenceTypeLaneway() || isPolicyTypePersonalPropertyExtension()

    const residenceTypeLanewayMessage = () => {
        if(!isResidenceTypeLaneway()) {
            return [];
        }

        const dwellings = _.get(jobVM,'lobData.homeowners.coverables.dwellings.value',[]);

        if(dwellings.length === 0){
            return [translator(messages.isDwellingAvailable)];
        }

        return [];
    }

    const editablePrimaryIndicator = useCallback(() => {
        const dwellings = _.get(jobVM, 'lobData.homeowners.coverables.dwellings.value', []);
        const existingPrimaryDwelling = dwellings.findIndex((dwelling) => dwelling.yourHome.primaryDwelling_EXT);

        return existingPrimaryDwelling !== selectedIndex;
    }, [jobVM, selectedIndex]);

    const overrideProps = {
        '@field': {
            parentNode: riskView,
            readOnly
        },
        riskType: {
            availableValues: availableRiskTypes,
            disabled: isSavedRisk(),
            onValueChange: changedRiskType,
        },
        residenceType: {
            availableValues: residenceTypeList,
            disabled: !isRiskTypeSet(),
            onValueChange: residenceTypeChanged,
            showErrors : showErrors || isResidenceTypeLaneway(),
            validationMessages : residenceTypeLanewayMessage()
        },
        duplicatAddressContainer: {
            visible: riskView && !!riskView.hasDuplicateAddress && isEditMode
        },
        duplicateAddressContent: {
            content: WMICRichTextUtil.translateRichText(translator(messages.duplicateAddress))
        },
        riskLocation: {
            availableValues: getPreferredAddressDropDownValues(),
            value: riskLocationIndex,
            onValueChange: handleAddressDropDownValueChange,
            disabled: isRiskLocationDisabled,
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            visible: !isResidenceTypeLaneway()
        },
        outsideOfRateableTerritoryMessageContainer: {
            visible: isOutsideOfRateableTerritoryMessageVisible()
        },
        addLocationBtnContainer: {
            visible: isEditMode && !isResidenceTypeLaneway()
        },
        country: {
            readOnly: true
        },
        addLocationBtn: {
            disabled: showEditAddressDiv
        },
        addressContainer: {
            visible: showEditAddressDiv
        },
        dwellingPropertyDetailsAddressLookup: {
            addressVM: _.get(riskView, 'yourHome.locationAddress', {}),
            onValidate: setComponentValidation,
            hideBorder: false,
            hideButtons: false,
            showErrors: showErrors || showLocationErrors,
            readOnlyCountry: true,
            onSave: updateAddress,
            onCancel: cancelAddressChange
        },
        associatedDwelling: {
            value: associatedDwellingPublicId,
            availableValues: lanewayOptions,
            onValueChange: lanewayOptionsChange,
            visible: showAssociatedDwelling(),
            disabled: isLanewayOptionsDisabled(),
            label: getAssociatedDwellingTranslationKey(),
        },
        unitNumber: {
            onValueChange: unitNumberChanged,
            visible: isResidenceTypeLaneway()
        },
        numberOfSelfContainedUnits: {
            onValueChange: onNumberOfSelfContainedUnitsChange,
            forceLabel: numberOfSuitesAndUnitLabel
        },
        csaLabelType: {
            onValueChange: clearCsaLabelTypeOtherField
        },
        dwellingUsage: {
            availableValues: dwellingUsageList,
            disabled: disabledDwellingUsage(),
            onValueChange: dwellingUsageChanged
        },
        occupancy: {
            availableValues: occupancyList,
            disabled: !isRiskTypeSet(),
            onValueChange: occupancyChanged
        },
        questionnaireReceived: {
            onValueChange: resetQuestionnaire
        },
        immediateFamilyType: {
            onValueChange: immediateFamilyChanged
        },
        coverageFor: {
            onValueChange: coverageForChanged
        },
        absenteeLandlord: {
            visible: isAbsenteeLandlordVisible()
        },
        propertyManagement: {
            visible: isPropertyManagementVisible()
        },
        coverageType: {
            availableValues: coverageTypeList,
            disabled: isCoverageTypeDisabled()
        },
        primaryIndicator: {
            readOnly: !editablePrimaryIndicator() || readOnly,
        },
        shuttleTankProtected: {
            visible: superiorShuttleTankerVisible()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddAddress: addAddress,
            onCancelAddressChange: cancelAddressChange,
            onUpdateAddress: updateAddress,
            onValidate
        },
        resolveComponentMap: {
            WMICAddressDetails
        },
        resolveDataProps: getDataPropsResolver({ model: riskView, translator })
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onModelChange={updateRiskView}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHODwellingPropertyDetails.propTypes = {
    id: PropTypes.string.isRequired,
    riskView: PropTypes.any.isRequired,
    jobVM: PropTypes.any.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    onRiskViewChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICHODwellingPropertyDetails;
