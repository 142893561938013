import React, {
    useEffect,
    useContext,
    useState, useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    WMICJurisdictionUtil,
    WMICDriverUtil,
    WMICDateTimeService,
    JURISDICTIONS,
    POLICY_HISTORY_ACTIONS,
    LOSS_HISTORY_ACTIONS,
    DASH_REPORTS_CONSTANTS,
    LOSS_HISTORY_STATUS,
    SOURCE_SYSTEM,
    CONSTANTS,
    WMICRichTextUtil,
} from 'wmic-pe-portals-utils-js';

import { useModal } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { WMICLossHistoryEntryComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICListDownloadButton } from 'wmic-pe-components-platform-react';

import WMICAddPolicyComponent from './WMICAddPolicyComponent/WMICAddPolicyComponent';
import metadata from './WMICDriverPolicyHistoryComponent.metadata.json5';
import messages from './WMICDriverPolicyHistoryComponent.messages.js';

const PREVIOUS_INSURANCE_PATH = 'policyHistory_WMIC.previousInsurances';
const DEFAULT_CARRIER_COUNTRY_CODE = 'CA';

let carriersList;

function WMICDriverPolicyHistoryComponent(props) {
    const {
        id,
        driverVM,
        onValidate,
        isEditMode,
        baseData,
        updateDriver,
        authHeader,
        jobVM
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const modalApi = useModal();
    const [editingPrevInsuranceIndex, updateEditingPrevInsuranceIndex] = useState(-1);
    const [editingDashEntryIndex, updateEditingDashEntryIndex] = useState(-1);
    const [selectedPolicy, updateSelectedPolicy] = useState(null);
    const [policyAction, updatePolicyAction] = useState();
    const [isPolicyActionBtnDisabled, setPolicyActionBtnDisabled] = useState(false);
    const [policyBackup, setPolicyBackup] = useState()

    const [selectedLossHistoryEntry, updateSelectedLossHistoryEntry] = useState(null);
    const [lossHistoryEntryAction, updateLossHistoryEntryAction] = useState();
    const [isLossHistoryActionBtnDisabled, setLossHistoryEntryActionBtnDisabled] = useState(false);

    const { onValidate: setComponentValidation, isComponentValid, registerComponentValidation } = useValidation(id);

    const isDashAvailable = _.get(driverVM, `dashContainer_Ext.aspects.ocular`);

    const isDriverPreviousInsuranceHistoryValid = useCallback(() => {
        const prevInsurances = _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, []);
        return prevInsurances.length > 0
    }, [driverVM]);

    useEffect(() => {
        registerComponentValidation(isDriverPreviousInsuranceHistoryValid);
    }, [registerComponentValidation, isDriverPreviousInsuranceHistoryValid]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, driverVM, id]);

    const isNewlyAddedAssignedDriver = useCallback( () => 
        !driverVM.isExistingInPreviousPeriodDriver_WMIC.value &&
        WMICDriverUtil.isAssignedDriver(driverVM),
    [driverVM]);

    const autoPlusWarningMessage = useMemo(() => {
        if (
            isNewlyAddedAssignedDriver() && !driverVM.dateAutoPlusOrdered_WMIC.value
        ) {
            return translator(messages.requiredToBind);
        }
        
        return undefined;
    }, [driverVM.dateAutoPlusOrdered_WMIC.value, isNewlyAddedAssignedDriver, translator]);

    useEffect(() => {
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.rateAsOfDate.value], true, authHeader)
            .then((carriers) => {
                carriers.forEach((item) => {
                    item.name = item.carrier
                    item.code = item.carrier
                })
                carriersList = carriers;
            });
        if (_.get(driverVM, 'policyHistory_WMIC.value.previousInsurances') === undefined){
            _.set(driverVM, 'policyHistory_WMIC.value.previousInsurances', []);
            updateDriver(driverVM);
        }
    }, [])

    const enableLossHistoryActionButtons = () => {
        updateSelectedLossHistoryEntry(null);
        updateLossHistoryEntryAction(undefined);
        setLossHistoryEntryActionBtnDisabled(false);
    };

    const createLossHistoryVM = (data) => {
        return viewModelService.create(
            data,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.LossHistoryRequestDTO_Ext',
            jobVM.aspects.context()
        );
    };

    const isReorderModalVisible = (driver) => driver.hasRecentNonManualLossHistoryRequest_Ext || driver.dashReports_Ext.some(report => !report.publicID && report.sourceSystem === SOURCE_SYSTEM.GPA);

    const retrieveDashReport = async (automaticDashEntryVM) => {
        if (lossHistoryEntryAction === LOSS_HISTORY_ACTIONS.ADD) {
            const driverVal = driverVM.value;
            let result = null;

            if (isReorderModalVisible(driverVal)) {
                result = await modalApi.showConfirm({
                    title: messages.emptyTitle,
                    message: translator(messages.reorderReport, {
                        driverNum: driverVal.driverNumber_WMIC,
                        driverName: driverVal.displayName
                    })
                });
            }

            if ([null, CONSTANTS.MODAL_RESULT.CONFIRM].includes(result)) {
                const dashVM = createLossHistoryVM(automaticDashEntryVM.value);
                _.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE).push(dashVM.value);
            } else {
                return _.noop();
            }

        } else if (lossHistoryEntryAction === LOSS_HISTORY_ACTIONS.EDIT) {
            const previousAutomaticEntryVM = _.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE);
            previousAutomaticEntryVM.splice(editingDashEntryIndex, 1, selectedLossHistoryEntry.value);
        }
        updateDriver(driverVM);
        enableLossHistoryActionButtons();

        return _.noop();
    };

    const cancelDashEntryAt = (toRemoveIdx) => {
        const prevDashReportsArr = _.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE, []);
        prevDashReportsArr[toRemoveIdx].orderStatus = LOSS_HISTORY_STATUS.CANCELLED;
        _.set(driverVM, DASH_REPORTS_CONSTANTS.VALUE, prevDashReportsArr);
    };

    const claimsHistoryLetterOfExperienceHeader = useMemo(() => {
        const autoPlusLabelCondition = _.get(driverVM, `autoPlusLabelCondition_WMIC.aspects.ocular`)
        return translator(autoPlusLabelCondition ? messages.claimHistoryReportHeading : messages.autoPlusHeading);
    }, [driverVM, translator])

    const claimsHistoryLetterOfExperienceDateLabel = useMemo(() => {
        const autoPlusLabelCondition = _.get(driverVM, `autoPlusLabelCondition_WMIC.aspects.ocular`)
        return translator(autoPlusLabelCondition ? messages.claimHistoryReportDate : messages.autoPlusDate);
    }, [driverVM, translator])

    const isDashWarningMessageVisible = () => isNewlyAddedAssignedDriver() &&
        _.isEmpty(_.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE, []));

    const getPreviousYearsInsuranceLabelNumber = () => {
        return WMICJurisdictionUtil.isBaseState(baseData, ...JURISDICTIONS.MARITIMES) ? 9 : 15;
    }

    const enablePolicyActionButtons = () => {
        updateSelectedPolicy(null);
        updatePolicyAction(undefined);
        setPolicyActionBtnDisabled(false);
    }

    const disablePolicyActionButtons = (action) => {
        updatePolicyAction(action);
        setPolicyActionBtnDisabled(false);
    }

    const disableLossHistoryActionButtons = (action) => {
        updateLossHistoryEntryAction(action);
        setLossHistoryEntryActionBtnDisabled(false);
    };

    const removePolicyAt = (toRemoveIdx) => {
        const prevInsuranceArr = _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, []);
        prevInsuranceArr.splice(toRemoveIdx, 1);
        _.set(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, prevInsuranceArr);
    };

    const removeManualEntryAt = (toRemoveIdx) => {
        const dashReportsArr = _.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE, []);
        dashReportsArr.splice(toRemoveIdx, 1);
    };

    const isViewMode = () => {
        return ![POLICY_HISTORY_ACTIONS.ADD, POLICY_HISTORY_ACTIONS.EDIT].includes(policyAction);
    };

    const isLossHistoryViewMode = () => {
        return ![LOSS_HISTORY_ACTIONS.ADD, LOSS_HISTORY_ACTIONS.EDIT].includes(lossHistoryEntryAction);
    };

    const createPrevInsuranceVM = (data = {}) => {
        data.isAssignedDriver = WMICDriverUtil.isAssignedDriver(driverVM);
        _.set(data, 'carrierCountry', data.carrierCountry ?? DEFAULT_CARRIER_COUNTRY_CODE);

        return viewModelService.create(
            data,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.DriverPreviousInsuranceDTO_WMIC', 
            jobVM.aspects.context()
        );
    }

    const addPolicy = () => {
        disablePolicyActionButtons(POLICY_HISTORY_ACTIONS.ADD);
        setPolicyActionBtnDisabled(true);

        const policyHistoryVM = createPrevInsuranceVM();
        updateSelectedPolicy(policyHistoryVM);
        updateDriver(driverVM);
    };

    const cancelPolicy = () => {
        updateDriver(driverVM);
        enablePolicyActionButtons();
    };

    const editPolicy = (policyVM, index) => {
        disablePolicyActionButtons(POLICY_HISTORY_ACTIONS.EDIT);
        setPolicyActionBtnDisabled(true);
        const editCopy = createPrevInsuranceVM(policyVM.value);
        setPolicyBackup(policyVM)
        updateSelectedPolicy(editCopy);
        updateEditingPrevInsuranceIndex(index);
    };

    const savePolicy = (policyVM) => {
        if (policyAction === POLICY_HISTORY_ACTIONS.ADD) {
            _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`).push(policyVM.value);
        } else if (policyAction === POLICY_HISTORY_ACTIONS.EDIT) {
            const previousPolicyVM = _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`);
            previousPolicyVM.splice(editingPrevInsuranceIndex, 1, selectedPolicy.value);
        }

        updateDriver(driverVM);
        enablePolicyActionButtons();
    };

    const removePolicy = (_policy, policyIndex) => {
        removePolicyAt(policyIndex);
        updateDriver(driverVM);
        updateSelectedPolicy(null);
    };

    const handleSelectPolicy = (policyVM) => {
        if (isViewMode() && policyVM) {
            const aSelectedPolicy = createPrevInsuranceVM(policyVM.value);
            updateSelectedPolicy(aSelectedPolicy);
        }
    };

    const addManualEntry = () => {
        disableLossHistoryActionButtons(LOSS_HISTORY_ACTIONS.ADD);
        setLossHistoryEntryActionBtnDisabled(true);
        const manualEntryData = {
            dateRequested: '',
            orderStatus: LOSS_HISTORY_STATUS.RECEIVED,
            sourceSystem: SOURCE_SYSTEM.MANUAL,
            isReadOnly: false,
        };
        const manualEntryHistoryVM = createLossHistoryVM(manualEntryData);
        updateSelectedLossHistoryEntry(manualEntryHistoryVM);
        updateDriver(driverVM);
    };

    const addDashEntry = () => {
        disableLossHistoryActionButtons(LOSS_HISTORY_ACTIONS.ADD);
        setLossHistoryEntryActionBtnDisabled(true);
        const dashEntryData = {
            dateRequested: WMICDateTimeService.today(),
            sourceSystem: SOURCE_SYSTEM.GPA,
            isReadOnly: false,
        };
        const dashEntryHistoryVM = createLossHistoryVM(dashEntryData);
        updateSelectedLossHistoryEntry(dashEntryHistoryVM);
        updateDriver(driverVM);
    };

    const cancelLossHistoryEntry = () => {
        updateDriver(driverVM);
        enableLossHistoryActionButtons();
    };

    const editLossHistoryEntry = (lossHistoryEntryVM, index) => {
        disableLossHistoryActionButtons(LOSS_HISTORY_ACTIONS.EDIT);
        setLossHistoryEntryActionBtnDisabled(true);
        const editCopy = createLossHistoryVM(lossHistoryEntryVM.value);
        updateSelectedLossHistoryEntry(editCopy);
        updateEditingDashEntryIndex(index);
    };

    const saveManualEntry = (manualEntryVM) => {
        if (lossHistoryEntryAction === LOSS_HISTORY_ACTIONS.ADD) {
            _.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE).push(manualEntryVM.value);

        } else if (lossHistoryEntryAction === LOSS_HISTORY_ACTIONS.EDIT) {
            const previousManualEntryVM = _.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE);
            previousManualEntryVM.splice(editingDashEntryIndex, 1, selectedLossHistoryEntry.value);
        }
        updateDriver(driverVM);
        enableLossHistoryActionButtons();
    };

    const withdrawLossHistoryEntry = (lossHistoryEntry, lossHistoryEntryIndex) => {
        if (lossHistoryEntry.value.orderStatus === LOSS_HISTORY_STATUS.ORDERED) {
            cancelDashEntryAt(lossHistoryEntryIndex);
        } else {
            removeManualEntryAt(lossHistoryEntryIndex);
        }
        updateDriver(driverVM);
        updateSelectedLossHistoryEntry(null);
    };

    const handleSelectLossHistoryEntry = (lossHistoryVM) => {
        if (isLossHistoryViewMode() && lossHistoryVM) {
            const aSelectedLossHistoryEntry = createLossHistoryVM(lossHistoryVM.value);
            updateSelectedLossHistoryEntry(aSelectedLossHistoryEntry);
        }
    };

    const handleSaveLossHistoryEntry = (lossHistoryEntry) => {
        return lossHistoryEntry.value.sourceSystem === SOURCE_SYSTEM.GPA
            ? retrieveDashReport(lossHistoryEntry)
            : saveManualEntry(lossHistoryEntry);
    };

    const isAutoPlusAvailable = useMemo(() => {
        return _.get(driverVM, `dateAutoPlusOrdered_WMIC.aspects.ocular`)
    }, [baseData])

    const getDateElement = (date) => {
        return <span>{WMICDateTimeService.toMidnightDate(date)}</span>;
    };

    const displayDashRequestedDate = (lossHistoryEntry) => {
        const lossHistoryDateRequested = lossHistoryEntry.value.dateRequested;
        return lossHistoryDateRequested
            ? getDateElement(lossHistoryDateRequested)
            : '';
    };

    const displayDashRetrievedDate = (lossHistoryEntry) => {
        const lossHistoryDateRetrieved = lossHistoryEntry.value.dateReceived;
        return lossHistoryDateRetrieved
            ? getDateElement(lossHistoryDateRetrieved)
            : '';
    };

    const getRequestedStatus = (lossHistoryEntry) => {
        return lossHistoryEntry?.value?.orderStatus ?? LOSS_HISTORY_STATUS.REQUESTED;
    };

    const displayDashStatus = (lossHistoryEntry) => {
        return <span>{getRequestedStatus(lossHistoryEntry)}</span>;
    };

    const sortLossHistoryDatesDesc = (date1, date2) => {
        return WMICDateTimeService.compareIgnoreTime(
            WMICDateTimeService.localDate2Date(date2.dateRequested),
            WMICDateTimeService.localDate2Date(date1.dateRequested)
        );
    };

    const orderedHistoryReportList = () => {
        _.set(driverVM, DASH_REPORTS_CONSTANTS.VALUE,
            _.get(driverVM, DASH_REPORTS_CONSTANTS.VALUE, [])
                .toSorted(sortLossHistoryDatesDesc));
        return _.get(driverVM, DASH_REPORTS_CONSTANTS.CHILDREN, []);
    };

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: !isEditMode
        },
        prevInsuranceInfoMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.previousInsuranceInfoMsg, { years: getPreviousYearsInsuranceLabelNumber() }))
        },
        previousInsuranceInfoMsg:{
            visible: _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, []).length === 0 && WMICDriverUtil.isAssignedDriver(driverVM)
        },
        policyDataList: {
            VMList: _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.insurerName),
                    path: 'carrierName'
                },
                {
                    headerText: translator(messages.policy),
                    path: 'policyNumber'
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'riskType'
                }
            ],
            onEditAction: editPolicy,
            onRemoveAction: removePolicy,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingPrevInsuranceIndex,
            updateSelectedCardIndex: updateEditingPrevInsuranceIndex,
            isEditing: isPolicyActionBtnDisabled || !isEditMode,
            readOnly: !isEditMode,
            onClickAction: handleSelectPolicy
        },
        policyAddButton: {
            disabled: isPolicyActionBtnDisabled,
            visible: isEditMode
        },
        policyForm: {
            onValidate: setComponentValidation,
            visible: !!selectedPolicy && isEditMode,
            selectedPolicy,
            savePolicy,
            cancelPolicy,
            driverVM,
            baseData,
            updateSelectedPolicy,
            updateDriver,
            carriersList,
            policyViewMode: isViewMode(),
            isEditMode
        },
        autoPlusContainer: {
            visible: isAutoPlusAvailable && !isDashAvailable
        },
        dashContainer: {
            visible: isDashAvailable,
        },
        dashWarningContainer: {
            visible: isDashWarningMessageVisible(),
        },
        dashWarningMessage: {
            content: translator(messages.requiredToBindDash),
        },
        autoPlusHeading: {
            title: claimsHistoryLetterOfExperienceHeader
        },
        dateAutoPlusOrderedField: {
            label: claimsHistoryLetterOfExperienceDateLabel,
            secondaryLabel: autoPlusWarningMessage
        },
        lossHistoryEntryDataList: {
            VMList: orderedHistoryReportList(),
            VMData: [
                {
                    headerText: translator(messages.dateRequested),
                    getData: displayDashRequestedDate,
                },
                {
                    headerText: translator(messages.dateReceived),
                    getData: displayDashRetrievedDate,
                },
                {
                    headerText: translator(messages.orderStatus),
                    getData: displayDashStatus,
                },
                {
                    headerText: translator(messages.source),
                    path: 'sourceSystem',
                },
            ],
            onEditAction: editLossHistoryEntry,
            onRemoveAction: withdrawLossHistoryEntry,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingDashEntryIndex,
            updateSelectedCardIndex: updateEditingDashEntryIndex,
            isEditing: isLossHistoryActionBtnDisabled || !isEditMode,
            readOnly: !isEditMode,
            onClickAction: handleSelectLossHistoryEntry,
            customElement: WMICListDownloadButton,
        },
        retrieveDashButton: {
            disabled: isLossHistoryActionBtnDisabled,
            visible: isEditMode,
        },
        manualDashEntryAddButton: {
            disabled: isLossHistoryActionBtnDisabled,
            visible: isEditMode,
        },
        lossHistoryEntryForm: {
            onValidate: setComponentValidation,
            visible: !!selectedLossHistoryEntry && isEditMode,
            selectedLossHistoryEntry,
            saveLossHistoryEntry: handleSaveLossHistoryEntry,
            cancelLossHistoryEntry,
            updateSelectedLossHistoryEntry,
            isStatusRequested: getRequestedStatus(selectedLossHistoryEntry) === LOSS_HISTORY_STATUS.REQUESTED,
            isEditMode,
            lossHistoryViewMode: isLossHistoryViewMode(),
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            addPolicy,
            addManualEntry,
            addDashEntry
        },
        resolveComponentMap: {
            WMICAddPolicyComponent,
            WMICLossHistoryEntryComponent
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            onModelChange={updateDriver}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICDriverPolicyHistoryComponent.propTypes = {
    driverVM: PropTypes.shape({}).isRequired,
    updateDriver: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    baseData: PropTypes.shape({}).isRequired,
    isEditMode: PropTypes.bool.isRequired
};

export default WMICDriverPolicyHistoryComponent;
